import React from 'react';
import SimpleReactFooter from "simple-react-footer";
import './Footer.css'
const Footer = () => {


    return (
        <div className='footer'>
            <span>Proudly Created By gSARC Team</span>

        </div>
    );
}

export default Footer;
